define("discourse/plugins/discourse-lazy-neattube/initializers/neattube-videos", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-lazy-neattube/lib/neattube-video-attributes", "@ember/template-factory"], function (_exports, _pluginApi, _neattubeVideoAttributes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initNeattubeEmbed(api) {
    api.decorateCookedElement((cooked, helper) => {
      if (cooked.classList.contains("d-editor-preview")) {
        return;
      }
      const neattubeContainers = cooked.querySelectorAll(".neattube-video-container");
      neattubeContainers.forEach(container => {
        const siteSettings = api.container.lookup("site-settings:main");
        const videoAttributes = (0, _neattubeVideoAttributes.default)(container);
        if (siteSettings[`lazy_neattube_enabled`]) {
          const onLoadedVideo = () => {
            const postId = cooked.closest("article")?.dataset?.postId;
            if (postId) {
              api.preventCloak(parseInt(postId, 10));
            }
          };
          const neattubeVideo = helper.renderGlimmer("p.neattube-video-wrapper", (0, _templateFactory.createTemplateFactory)(
          /*
            <NeattubeVideo @videoAttributes={{@data.param}} @onLoadedVideo={{@data.onLoadedVideo}}/>
          */
          {
            "id": "WjPrrymx",
            "block": "[[[8,[39,0],null,[[\"@videoAttributes\",\"@onLoadedVideo\"],[[30,1,[\"param\"]],[30,1,[\"onLoadedVideo\"]]]],null]],[\"@data\"],false,[\"neattube-video\"]]",
            "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-lazy-neattube/initializers/neattube-videos.js",
            "isStrictMode": false
          }), {
            param: videoAttributes,
            onLoadedVideo
          });
          container.replaceWith(neattubeVideo);
        }
      });
    }, {
      onlyStream: true,
      id: "discourse-lazy-neattube"
    });
  }
  var _default = _exports.default = {
    name: "discourse-lazy-neattube",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.6.0", initNeattubeEmbed);
    }
  };
});